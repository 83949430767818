<template>
  <div class="page-header row no-gutters py-4">
    <div class="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
      <span class="text-uppercase page-subtitle">Overview</span>
      <h3 class="page-title">Endpoint and requests</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-12 mb-1">
      <div id="accordion">
        <div class="card card-small mb-1">
          <div class="card-header rounded-bottom" id="headingOne">
            <h5 class="mb-0">
              <button type="button" class="mb-2 btn btn-sm btn-outline-success">POST</button>
              <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                Example POST request
              </button>
            </h5>
          </div>

          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body">
              This example illustrates how to using POST request /api/verify-document endpoint.
            </div>
          </div>
        </div>
        <div class="card card-small mb-1">
          <div class="card-header rounded-bottom" id="headingTwo">
            <h5 class="mb-0">
              <button type="button" class="mb-2 btn btn-sm btn-outline-royal-blue">GET</button>
              <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Example GET request
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              Disable
            </div>
          </div>
        </div>
        <div class="card card-small mb-1">
          <div class="card-header rounded-bottom" id="headingThree">
            <h5 class="mb-0">
              <button type="button" class="mb-2 btn btn-sm btn-outline-warning">PUT</button>
              <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Example PUT request
              </button>
            </h5>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
            <div class="card-body">
              Disable
            </div>
          </div>
        </div>
        <div class="card card-small mb-1">
          <div class="card-header rounded-bottom" id="headingThree">
            <h5 class="mb-0">
              <button type="button" class="mb-2 btn btn-sm btn-outline-danger">DELETE</button>
              <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Example DELETE request
              </button>
            </h5>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
            <div class="card-body">
              Disable
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>