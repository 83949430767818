<script setup>

</script>

<template>
  <div class="page-header row no-gutters py-4">
    <div class="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
      <span class="text-uppercase page-subtitle">Overview</span>
      <h3 class="page-title">Status and error codes</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-12 mb-1">
      <table class="table">
        <tbody>
          <tr>
            <td><p><strong>401 Unauthorized</strong></p> The client doesn’t have correct authentication credentials.</td>
          </tr>
          <tr>
            <td><p><strong>403 Forbidden</strong></p>The server is refusing to respond. This is typically caused by incorrect access scopes.</td>
          </tr>
          <tr>
            <td><p><strong>404 Not Found</strong></p>The requested resource was not found but could be available again in the future.</td>
          </tr>
          <tr>
            <td><p><strong>429 Too Many Requests</strong></p>The client has exceeded the rate limit.</td>
          </tr>
          <tr>
            <td><p><strong>5xx Errors</strong></p>An internal error occurred in NEAC API</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  
</template>