<script setup>
import { defineComponent } from 'vue';

defineProps({
  msg: {
    type: String,
    required: true
  }
})
</script>

<template>
  <div class="container">
    <div class="col-md-12 col-sm-12">
      Blog được lập ra với mục đích nghiên cứu, học tập và chia sẻ kiến thức của tôi, tất cả nội dung và công cụ được phát triển và đăng tải trên Blog là miễn phí.
    </div>
  </div>
</template>