<script setup>
import Sidebar from '../components/pki/Sidebar.vue';
import Nav from '../components/pki/Nav.vue';
import Footer from '../components/pki/Footer.vue';
import { defineComponent } from 'vue';

defineComponent({
  name: 'LayoutPki'
});

defineProps({
  components: {
    Sidebar
  }
});
</script>

<template>
    <div class="color-switcher-toggle animated pulse infinite">
      <i class="material-icons">settings</i>
    </div>
    <div class="container-fluid">
      <div class="row">
        <Sidebar />
        <main class="main-content col-lg-10 col-md-9 col-sm-12 p-0 offset-lg-2 offset-md-3">
          <div class="main-navbar sticky-top bg-white">
            <NavComponent />
          </div>
          <div class="main-content-container container-fluid px-4">
            <router-view></router-view>
          </div>
          <footer class="main-footer d-flex p-2 px-3 bg-white border-top">
            <FooterComponent />
          </footer>
        </main>
      </div>
    </div>
</template>