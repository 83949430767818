<script>
import { defineComponent } from 'vue';
import Spinner from '@/components/signature/_Spinner.vue';

export default defineComponent({
  name: 'RemoteSigningSite',

  data: function () {
    return {
      form: {
        user_id: '017097009063',
        selectedOptionCa: 'FPT-CA',
        timestampUrl: null,
        reason: null,
        location: null,
        serial_number: null,
        visible_signature: true,
        enable_timestamp: false,
      },
      serial_numbers: null,
      selectedSerialNumber: null,
      selectedFile: null,
      formData: new FormData(),
      loadingStates: {
        validateButton: false,
        signButton: false,
      },
    };
  },

  methods: {
    validateCertificate() {
      this.loadingStates.validateButton = true;
      this.formData = new FormData();
      this.formData.append('user_id', this.form.user_id);
      this.formData.append('ca_name', this.form.selectedOptionCa);
      console.log(this.formData)
      this.$axios.post('/digital-signature/api_remote_signing_get_cert', this.formData, { headers: { 'X-CSRFToken': this.$csrfToken } })
      .then(response => {
        this.loadingStates.validateButton = false;
        console.log(response)
        if (response.data.status != 200) {
          alert(response.data.message);
        } else if (response.data.status == 200) {
          this.serial_numbers = response.data.data;
          this.form.serial_number = this.serial_numbers ? this.serial_numbers[0] : null
        }
      })
      .catch(error => {
        console.error(error);
        // Xử lý lỗi (nếu có)
      });
    },

    handleFileChange(event) {
      if (!this.serial_numbers) {
        this.$refs.fileInput.value = null;
        alert("Require validate certificate!");
        return;
      }

      this.selectedFile = event.target.files[0];
      this.formData = new FormData();
      this.formData.append('file', this.selectedFile);

      this.$axios.post('/digital-signature/api_upload_file_pdf', this.formData, { headers: { 'X-CSRFToken': this.$csrfToken } })
      .then(response => {
        if (response.data.status == 200) {
          renderPdfFile(response.data.data);
        } else {
          alert(response.data.message);
        }
      })
      .catch(error => {
        console.error(error);
      });
    },

    handleResetForm() {
      this.form.user_id = '017097009063';
      this.form.selectedOptionCa = 'FPT-CA';
      this.form.serial_number = null;
      this.serial_numbers = null;
      this.selectedFile = null;
      this.formData = new FormData();
      this.loadingStates.validateButton = false;
      this.loadingStates.signButton = false;
    },

    submitSigning() {
      if (!this.serial_numbers) {
        this.$refs.fileInput.value = null;
        alert("Require validate certificate!");
        return;
      }

      if (!this.selectedFile) {
        alert("Require upload file pdf!");
        return;
      }

      if (!this.form.serial_number) {
        alert("Require serial number");
        return;
      }

      this.loadingStates.signButton = true;

      this.formData = new FormData();
      this.formData.append('pos_left', Math.floor(pos_left));
      this.formData.append('pos_top', Math.floor(pos_top));
      this.formData.append('fname', this.selectedFile.name);
      this.formData.append('page', pageNum);

      for (const input in this.form) {
        this.formData.append(input, this.form[input]);
      }

      this.$axios.post(
        '/digital-signature/api_remote_signing_sign',
        this.formData,
        { headers: { 'X-CSRFToken': this.$csrfToken } }
      )
        .then(response => {
          this.loadingStates.signButton = false;

          if (response.data.status == 200) {
            
          } else {
            alert(response.data.message);
          }
          // Xử lý phản hồi từ server
        })
        .catch(error => {
          console.error(error);
          this.loadingStates.validateButton = false
          // Xử lý lỗi (nếu có)
        });
    },
  },

  components: {
    Spinner
  },
})

var pos_top = 0, pos_left = 0;
var pageNum = 1;

function renderPdfFile(url) {
  var container = document.getElementById('containerCanvasPdf')

  if (container) {
    // Đếm số lượng phần tử con
    var childCount = container.children.length;

    // Nếu số lượng phần tử con lớn hơn 2, thì xóa phần tử cuối cùng
    if (childCount > 1) {
      container.removeChild(container.lastChild);
    }
  }

  var canvas = document.createElement('canvas');
  canvas.setAttribute("id", "pdf_canvas_fabric");
  canvas.style.border = "1px dashed black";
  container.appendChild(canvas)

  var pdfDoc = null,
    pageRendering = false,
    pageNumPending = null,
    scale = 1,
    fabricCanvas = document.getElementById("pdf_canvas"),
    canvas = new fabric.Canvas('pdf_canvas_fabric', {
      backgroundColor: null,
    });

  const rect = new fabric.Rect({
    width: 100,
    height: 50,
    fill: '',
    stroke: 'red',
    strokeWidth: 2,
    left: 0,
    top: 0,
    selectable: true, // Cho phép chọn
    lockScalingX: true,
    lockScalingY: true, 
  });

  function renderPage(num) {
    pageRendering = true;

    console.log(num, '3')
    pdfDoc.getPage(num).then((page) => {
      var viewport = page.getViewport({ scale: scale });
      // fabricCanvas.setDimensions({
      //   width: viewport.width,
      //   height: viewport.height
      // });
      fabricCanvas.width = viewport.width
      fabricCanvas.height = viewport.height

      var renderContext = {
        canvasContext: fabricCanvas.getContext('2d'),
        viewport: viewport
      }

      console.log('aba11');
      var renderTask = page.render(renderContext).promise.then(() => {
        var bg = fabricCanvas.toDataURL("image/png");

        canvas.add(rect);
        canvas.setDimensions({
          width: viewport.width,
          height: viewport.height
        });
        canvas.setBackgroundImage(bg, canvas.renderAll.bind(canvas));

        pos_top = viewport.height - rect.get('top');
        pos_left = rect.get('left');

        rect.on('mousedown', function (options) {
          const { e } = options;
          rect.set({
            shadow: new fabric.Shadow({ color: 'rgba(0,0,0,0.3)', blur: 10, offsetX: 5, offsetY: 5 }),
          });
          rect.setCoords();
          canvas.requestRenderAll();
          canvas.setActiveObject(rect);
        });
      
        rect.on('mouseup', function (options) {
          const { e } = options;
          rect.set({
            shadow: null,
          });
          rect.setCoords();
          canvas.requestRenderAll();
        });
      
        canvas.on('mouse:up', function (options) {
          const activeObject = canvas.getActiveObject();
          if (activeObject) {
            activeObject.setCoords();
          }
        });
        
        canvas.on('object:modified', function(options) {
          const { target } = options;
          target.setCoords();
          pos_top = viewport.height - target.get('top');
          pos_left = target.get('left');
          console.log('Vị trí của đối tượng đã được sửa đổi:', pos_left, pos_top);
        });

        pageRendering = false;
        
      })
    })
    console.log(num, '2')
    document.getElementById('page_num').textContent = num;
  }

  function queueRenderPage(num) {
    if (pageRendering) {
      pageNumPending = num;
    } else {
      renderPage(num);
    }
  }

  function onPrevPage() {
    if (pageNum <= 1) {
      return
    }
    pageNum--;
    queueRenderPage(pageNum);
  }
  document.getElementById('prev').addEventListener('click', onPrevPage);

  function onNextPage() {
    if (pageNum >= pdfDoc.numPages) {
      return;
    }
    pageNum++;
    queueRenderPage(pageNum);
  }
  document.getElementById('next').addEventListener('click', onNextPage);

  pdfjsLib.getDocument(url).promise.then(function (pdf) {
    pdfDoc = pdf;
    document.getElementById('page_count').textContent = pdf.numPages;
    renderPage(pageNum);
  }, function (reason) {
    // PDF loading error
    console.error(reason);
  });
}
</script>

<template>
  <div class="card card-small mb-4">
    <div class="card-header border-bottom">
      <h6 class="m-0">Remote Signing Site</h6>
    </div>
    <ul class="list-group list-group-flush">
      
    </ul>
  </div>
</template>

<style scoped>

</style>
