import { createRouter, createWebHistory } from 'vue-router';
import PortfolioPage from './components/PortfolioPage.vue';
import AboutPage from './components/blog/About.vue';
// import SigningPage from './components/digital_signature/SigningPage.vue';
// import VerifyPage from './components/digital_signature/VerifyPage.vue';
import PostsPage from './components/blog/Posts.vue';
import DetailPage from './components/blog/Detail.vue';
// import HomePage from './components/digital_signature/HomePage.vue';
import LayoutBlog from './layouts/LayoutBlog.vue';
import SigningPage from './components/signature/SigningPage.vue';
import VerifyPage from './components/signature/VerifyPage.vue';
import Signature from './components/signature/Signature.vue';
import PrivacyPage from './components/PrivacyPage.vue';
import ContactPage from './components/ContactPage.vue';
import Documents from './components/documents/Documents.vue';
import VerifyCertificatePage from './components/documents/VerifyCertificatePage.vue';
import VerifyDocumentPage from './components/documents/VerifyDocumentPage.vue';
import OverviewPage from './components/documents/OverviewPage.vue';
// ROOT FILE
import Blog from './components/blog/Blog.vue';
import Tools from './components/Tools.vue';
import WelcomePage from './components/WelcomePage.vue';
import NotFoundPage from './components/home/NotFoundPage.vue';
import DigitalSignature from './components/digital_signature/DigitalSignature.vue';
import HomePage from './components/home/HomePage.vue';
import AuthenticationPage from './components/home/AuthenticationPage.vue';
import EndpoinPage from './components/home/EndpoinPage.vue';
import StatusCodePage from './components/home/StatusCodePage.vue';
import RateLimitPage from './components/home/RateLimitPage.vue';


const documents = {
  path: '/docs',
  name: 'Documents',
  component: Documents,
  meta: {
    layout: 'Guest',
    requiresAuth: false,
  },
  children: [
    {
      path: 'overview',
      name: 'OverviewPage',
      component: OverviewPage,
      meta: { 
        layout: 'Guest', 
        requiresAuth: false,
      },
    },
    {
      path: 'authentication',
      name: 'AuthenticationPage',
      component: AuthenticationPage,
      meta: { 
        layout: 'Guest', 
        requiresAuth: false,
      },
    },
    {
      path: 'rate-limits',
      name: 'RateLimitPage',
      component: RateLimitPage,
      meta: { 
        layout: 'Guest', 
        requiresAuth: false,
      },
    },
    {
      path: 'endpoints',
      name: 'EndpoinPage',
      component: EndpoinPage,
      meta: { 
        layout: 'Guest', 
        requiresAuth: false,
      },
    },
    {
      path: 'status-and-error-code',
      name: 'StatusCodePage',
      component: StatusCodePage,
      meta: { 
        layout: 'Guest', 
        requiresAuth: false,
      },
    },
    {
      path: 'verify-signature',
      name: 'VerifyDocumentPage',
      component: VerifyDocumentPage,
      meta: { 
        layout: 'Guest', 
        requiresAuth: false,
      },
    },
    {
      path: 'verify-certificate',
      name: 'VerifyCertificatePage',
      component: VerifyCertificatePage,
      meta: { 
        layout: 'Guest', 
        requiresAuth: false,
      },
    }
  ],
}
const blog = {
  path: '/blog',
  component: Blog,
  meta: { 
    layout: 'Blog', 
    requiresAuth: false,
  }, 
  children: [
    {
      path: 'posts',
      name: 'PostsPage',
      component: PostsPage,
      meta: { 
        layout: 'Blog', 
        requiresAuth: false,
      }, 
    },
    {
      path: '/read/:title_tag',
      name: 'DetailPost',
      component: DetailPage,
      meta: { 
        layout: 'Blog', 
        requiresAuth: false,
      }, 
    },
  ]
};
const tools = {
  path: '/tools',
  component: Tools,
  meta: { 
    layout: 'Blog', 
    requiresAuth: false,
  }, 
  children: [
    {
      path: 'signature',
      name: 'Signature',
      component: Signature,
      meta: { 
        layout: 'Blog', 
        requiresAuth: false,
      },
      children: [
        {
          path: 'sign',
          name: 'SigningPage',
          component: SigningPage,
          meta: { 
            layout: 'Blog', 
            requiresAuth: false,
          },
        },
        {
          path: 'verify',
          name: 'VerifyPage',
          component: VerifyPage,
          meta: { 
            layout: 'Blog', 
            requiresAuth: false,
          },
        },
      ]
    },
    {
      path: 'certificate',
      name: 'Certificate',
      component: Signature,
      meta: { 
        layout: 'Blog', 
        requiresAuth: false,
      },
      children: [
        {
          path: 'csr',
          name: 'Csr',
          component: SigningPage,
          meta: { 
            layout: 'Blog', 
            requiresAuth: false,
          },
        },
        {
          path: 'cer-pem-p7b',
          name: 'CerPemP7b',
          component: VerifyPage,
          meta: { 
            layout: 'Blog', 
            requiresAuth: false,
          },
        },
      ]
    }
  ]
};

const routes = [
  blog,
  tools,
  documents,
  {
    path: '/',
    // redirect: '/docs',
    name: 'HomePage',
    component: HomePage,
    meta: { 
      layout: 'Guest', 
      requiresAuth: false,
    }, 
  },
  {
    path: '/*',
    name: 'NotFoundPage',
    component: NotFoundPage,
    meta: {
      layout: 'Guest',
      requiresAuth: false,
    },
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage,
    meta: { 
      layout: 'Blog', 
      requiresAuth: false,
    }, 
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage,
    meta: { 
      layout: 'Blog', 
      requiresAuth: false,
    }, 
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    component: PrivacyPage,
    meta: { 
      layout: 'Blog', 
      requiresAuth: false,
    }, 
  },
  {
    path: '/portfolio',
    name: 'PortfolioPage',
    component: PortfolioPage,
    meta: { 
      layout: 'LayoutDefault', 
      requiresAuth: false,
    }, 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   document.title = to.name
// });

export default router;