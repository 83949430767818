<script setup>
import { ref, reactive } from 'vue';
import RemoteSigning from '@/components/signature/_RemoteSigning.vue';
import RemoteSigningSite from '@/components/signature/_RemoteSigningSite.vue';

// Khai báo biến và state
const selectedOption = ref('remote');
const remoteSigningRef = ref(null);

// const remoteSigning = reactive({
//   stepOneRemoteSigning: true,
//   stepTwoRemoteSigning: false,
//   stepThreeRemoteSigning: false,
//   selectedFile: null,
//   // formData: new FormData(),
// });

// Khai báo phương thức
// const handleResetForm = () => {
//   remoteSigning.stepOneRemoteSigning = true;
//   remoteSigning.stepTwoRemoteSigning = false;
//   remoteSigning.stepThreeRemoteSigning = false;
//   remoteSigning.selectedFile = null;
//   remoteSigning.formData = new FormData();
// };
</script>

<template>
  <div class="container mt-2">
    <div class="row">
      <div class="col-md-8 col-sm-12 col-xs-12 offset-md-2">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#">Signature</a></li>
              <li class="breadcrumb-item active" aria-current="page">Signing</li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 col-sm-12 col-xs-12 offset-md-2">
        <div class="card card-small mb-1">
          <div class="card-header border-bottom">
            <h6 class="m-0">Chọn hình thức ký</h6>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item p-0 px-3">
              <div class="row mt-3">
                <div class="col-sm-12 col-md-3 mb-3">
                  <!-- Remote -->
                  <fieldset>
                    <div class="form-check mb-1">
                      <input type="radio" name="remote" class="form-check-input" id="remote-signing" value="remote"
                        v-model="selectedOption">
                      <label class="form-check-label" for="remote-signing">RS cổng eSign</label>
                    </div>
                  </fieldset>
                  <!-- / Remote -->
                </div>
                <!-- <div class="col-sm-12 col-md-3 mb-3">
                  <fieldset>
                    <div class="form-check mb-1">
                      <input type="radio" name="usb" class="form-check-input" id="usb-signing" value="usb"
                        v-model="selectedOption">
                      <label class="form-check-label" for="usb-signing">USB Token</label>
                    </div>
                  </fieldset>
                </div> -->
                <div class="col-sm-12 col-md-3 mb-3">
                  <!-- Site Private key -->
                  <fieldset>
                    <div class="form-check mb-1">
                      <input type="radio" name="usb" class="form-check-input" id="site-signing" value="site"
                        v-model="selectedOption">
                      <label class="form-check-label" for="site-signing">RS Gurugrass</label>
                    </div>
                  </fieldset>
                  <!-- / Site Private key -->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 offset-md-2" v-if="selectedOption === 'remote'">
        <RemoteSigning ref="remoteSigningRef"/>
      </div>
      <div class="col-md-8 offset-md-2" v-if="selectedOption === 'site'">
        <RemoteSigningSite />
      </div>
    </div>
  </div>
</template>
