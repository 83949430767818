<script setup>
import { defineComponent } from 'vue';

defineComponent({
  name: 'Sidebar'
});
</script>
<template>
<aside class="main-sidebar col-12 col-md-3 col-lg-2 px-0">
  <div class="main-navbar">
    <nav class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0">
      <a class="navbar-brand w-100 mr-0" href="/digital-signature/home" style="line-height: 25px;">
        <div class="d-table m-auto">
          <!-- <img id="main-logo" class="d-inline-block align-top mr-1" style="max-width: 25px;" src="" alt="PKI TOOL"> -->
          <span class="d-none d-md-inline ml-1">PKI TOOL</span>
        </div>
      </a>
      <a class="toggle-sidebar d-sm-inline d-md-none d-lg-none">
        <i class="material-icons">&#xE5C4;</i>
      </a>
    </nav>
  </div>
  <form action="#" class="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none">
    <div class="input-group input-group-seamless ml-3">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <i class="fas fa-search"></i>
        </div>
      </div>
      <input class="navbar-search form-control" type="text" placeholder="Search for something..." aria-label="Search">
    </div>
  </form>
  <div class="nav-wrapper">
    <h6 class="main-sidebar__nav-title">Signature</h6>
    <ul class="nav nav--no-borders flex-column">
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle active" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="true">
          <i class="material-icons">&#xE7FD;</i>
          <span>Document Signing</span>
        </a>
        <div class="dropdown-menu dropdown-menu-small">
          <a class="dropdown-item" href="/digital-signature/signing">PDF</a>
          <a class="dropdown-item active" href="/digital-signature/signing">XML</a>
          <a class="dropdown-item" href="/digital-signature/signing">JSON</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle {% if request.resolver_match.view_name in 'guest_signature:guest_validator_pdf, guest_signature:guest_validator_xml, guest_signature:guest_validator_json' %}active{% endif %}" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
          <i class="material-icons">&#xE2C7;</i>
          <span>Document Validator</span>
        </a>
        <div class="dropdown-menu dropdown-menu-small">
          <a class="dropdown-item" href="/digital-signature/verify">PDF</a>
          <a class="dropdown-item" href="/digital-signature/verify">XML</a>
          <a class="dropdown-item" href="/digital-signature/verify">JSON</a>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle " data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
          <i class="material-icons">&#xE2C7;</i>
          <span>Timestamp Services</span>
        </a>
        <div class="dropdown-menu dropdown-menu-small">
          <a class="dropdown-item " href="file-manager-list.html">TSA Generator</a>
        </div>
      </li>
    </ul>
  </div>
</aside>
</template>