import './assets/main.css';
// import "./assets/custom.scss";

import { createApp, defineAsyncComponent } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import VueLazyLoad from 'vue3-lazy';
import router from './router';
import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap-grid.min.css';
// import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
// import './assets/styles/extras.1.3.1.min.css';
// import './assets/styles/shards-dashboards.1.3.1.min.css';

const el = document.getElementById('app');

const instance = axios.create({
    baseURL: 'http://127.0.0.1:8000', 
});

if (el) {
    const global = {...el.dataset};
    console.log('data: ', global.token);
    const app = createApp(App, global);

    app.config.globalProperties.$axios = instance;
    app.config.globalProperties.$csrfToken = global.token;
    app.provide('csrfToken', global.token);
    app.use(router);
    app.use(bootstrap);
    app.use(createPinia());
    app.use(VueLazyLoad, {
        // loading: 'dist/loading.gif',
        // error: 'dist/error.png'
    });
    app.mount('#app');
}
