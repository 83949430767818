<script setup>
import { ref, reactive, inject, watch } from 'vue';
import Spinner from '@/components/signature/_Spinner.vue';
import Captcha from '@/components/signature/_Captcha.vue';
import axios from 'axios';

const csrfToken = inject('csrfToken')
const form = reactive({
  user_id: '',
  sp_id: '',
  sp_password: '',
  selectedOptionCa: 'FPT-CA',
  timestampUrl: null,
  reason: null,
  location: null,
  serial_number: null,
  visible_signature: true,
  enable_timestamp: false,
});

const fileSignedUrl = ref('');
const captchaRef = ref('');
// const remoteSigningRef = ref(null);
const serial_numbers = ref([]);
// const selectedSerialNumber = ref(null);
const selectedFile = ref(null);
const loadingStates = reactive({
  validateButton: false,
  signButton: false,
});

const validateCertificate = async() => {
  loadingStates.validateButton = true;
  let formData = new FormData();
  formData.append('sp_id', form.sp_id);
  formData.append('sp_password', form.sp_password);
  formData.append('user_id', form.user_id);
  formData.append('ca_name', form.selectedOptionCa);

  try {
    const response = await axios.post('/digital-signature/api_remote_signing_get_cert', formData, { headers: { 'X-CSRFToken': csrfToken } })
  
    if (response.data.status == 200) {
      alert(response.data.message);
      serial_numbers.value = response.data.data;
      form.serial_number = serial_numbers.value ? serial_numbers.value[0] : null
      console.log(response)
    } else {
      alert(response.data.message);
    }
  } catch (error) {
    console.log(error)
  }
  
  loadingStates.validateButton = false;
};

const handleFileChange = async(event) => {
  if (!serial_numbers.value) {
    alert("Require validate certificate!");
    return;
  }

  if (!event.target.files || event.target.files.length === 0) {
    errorCaptchaHtml.value = '';
    alert("Please select a file to upload!");
    return;
  }

  selectedFile.value = event.target.files[0];
  console.log(selectedFile.value.name, 'đây nè')

  let formData = new FormData();
  formData.append('file', selectedFile.value);
  formData.append('captcha_0', captchaRef.value.captchaKey); // Tên của trường hidden captcha
  formData.append('captcha_1', captchaRef.value.captchaText);

  const response = await axios.post('/digital-signature/api_upload_file_pdf', formData, { headers: { 'X-CSRFToken': csrfToken } })
  console.log(response, 'babab')
  
  try {
    if (response.data.status == 200) {
      errorCaptchaHtml.value = '';
      renderPdfFile(response.data.data);
    } else {
      alert(response.data.message);
      errorCaptchaHtml.value = response.data.data.error_html.captcha;
    }
  } catch (error) {
    console.log("Lỗi trong lúc gọi JS" + error)
  }
  
  captchaRef.value.reloadCaptcha();
};

const handleResetForm = async() => {
  form.user_id = '';
  form.selectedOptionCa = 'FPT-CA';
  form.serial_number = null;
  serial_numbers.value = null;
  selectedFile.value = null;
  loadingStates.validateButton = false;
  loadingStates.signButton = false;
};

const submitSigning = async() => {
  try {
    console.log(selectedFile.value.name, serial_numbers.value)
    if (!serial_numbers.value) {
      alert("Require validate certificate!");
      return;
    }

    if (!selectedFile.value) {
      alert("Require upload file pdf!");
      return;
    }

    if (!form.serial_number) {
      alert("Require serial number");
      return;
    }

    loadingStates.signButton = true;

    let formData = new FormData();
    formData.append('pos_left', Math.floor(pos_left));
    formData.append('pos_top', Math.floor(pos_top));
    formData.append('fname', selectedFile.value.name);
    formData.append('page', pageNum);
    formData.append('enable_timestamp', form.enable_timestamp);
    formData.append('location', form.location);
    formData.append('reason', form.reason);
    formData.append('serial_number', form.serial_number);
    formData.append('sp_id', form.sp_id);
    formData.append('sp_password', form.sp_password);
    formData.append('selectedOptionCa', form.selectedOptionCa);
    formData.append('timestampUrl', form.timestampUrl);
    formData.append('user_id', form.user_id);
    formData.append('visible_signature', form.visible_signature);

    console.log(selectedFile.value.name, 'hehee')

    const response = await axios.post(
      '/digital-signature/api_remote_signing_sign',
      formData,
      { headers: { 'X-CSRFToken': csrfToken } }
    );

    loadingStates.signButton = false;
    loadingStates.validateButton = false;

    if (response.data.status == 200) {
      fileSignedUrl.value = response.data.data;
      console.log(fileSignedUrl.length)
      alert(response.data.message);
    } else {
      alert(response.data.message);
    }
  } catch (error) {
    console.log(error + 'lỗi trong lúc xử lý JS submit')
    loadingStates.signButton = false;
    loadingStates.validateButton = false;
  }
};
const errorCaptchaHtml = ref('')
var pos_top = 0, pos_left = 0;
var pageNum = 1;

watch(selectedFile, (newFile, oldFile) => {
  console.log('Selected file changed from', oldFile, 'to', newFile);
});

function renderPdfFile(url) {
  var container = document.getElementById('containerCanvasPdf')

  if (container) {
    // Đếm số lượng phần tử con
    var childCount = container.children.length;

    // Nếu số lượng phần tử con lớn hơn 2, thì xóa phần tử cuối cùng
    if (childCount > 1) {
      container.removeChild(container.lastChild);
    }
  }

  var canvas = document.createElement('canvas');
  canvas.setAttribute("id", "pdf_canvas_fabric");
  canvas.style.border = "1px dashed black";
  container.appendChild(canvas)

  var pdfDoc = null,
    pageRendering = false,
    pageNumPending = null,
    scale = 1,
    fabricCanvas = document.getElementById("pdf_canvas"),
    canvas = new fabric.Canvas('pdf_canvas_fabric', {
      backgroundColor: null,
    });

  const rect = new fabric.Rect({
    width: 100,
    height: 50,
    fill: '',
    stroke: 'red',
    strokeWidth: 2,
    left: 0,
    top: 0,
    selectable: true, // Cho phép chọn
    lockScalingX: true,
    lockScalingY: true, 
  });

  function renderPage(num) {
    pageRendering = true;

    console.log(num, '3')
    pdfDoc.getPage(num).then((page) => {
      var viewport = page.getViewport({ scale: scale });
      // fabricCanvas.setDimensions({
      //   width: viewport.width,
      //   height: viewport.height
      // });
      fabricCanvas.width = viewport.width
      fabricCanvas.height = viewport.height

      var renderContext = {
        canvasContext: fabricCanvas.getContext('2d'),
        viewport: viewport
      }

      console.log('aba11');
      var renderTask = page.render(renderContext).promise.then(() => {
        var bg = fabricCanvas.toDataURL("image/png");

        canvas.add(rect);
        canvas.setDimensions({
          width: viewport.width,
          height: viewport.height
        });
        canvas.setBackgroundImage(bg, canvas.renderAll.bind(canvas));

        pos_top = viewport.height - rect.get('top');
        pos_left = rect.get('left');

        rect.on('mousedown', function (options) {
          const { e } = options;
          rect.set({
            shadow: new fabric.Shadow({ color: 'rgba(0,0,0,0.3)', blur: 10, offsetX: 5, offsetY: 5 }),
          });
          rect.setCoords();
          canvas.requestRenderAll();
          canvas.setActiveObject(rect);
        });
      
        rect.on('mouseup', function (options) {
          const { e } = options;
          rect.set({
            shadow: null,
          });
          rect.setCoords();
          canvas.requestRenderAll();
        });
      
        canvas.on('mouse:up', function (options) {
          const activeObject = canvas.getActiveObject();
          if (activeObject) {
            activeObject.setCoords();
          }
        });
        
        canvas.on('object:modified', function(options) {
          const { target } = options;
          target.setCoords();
          pos_top = viewport.height - target.get('top');
          pos_left = target.get('left');
          console.log('Vị trí của đối tượng đã được sửa đổi:', pos_left, pos_top);
        });

        pageRendering = false;
        
      })
    })
    console.log(num, '2')
    document.getElementById('page_num').textContent = num;
  }

  function queueRenderPage(num) {
    if (pageRendering) {
      pageNumPending = num;
    } else {
      renderPage(num);
    }
  }

  function onPrevPage() {
    if (pageNum <= 1) {
      return
    }
    pageNum--;
    queueRenderPage(pageNum);
  }
  document.getElementById('prev').addEventListener('click', onPrevPage);

  function onNextPage() {
    if (pageNum >= pdfDoc.numPages) {
      return;
    }
    pageNum++;
    queueRenderPage(pageNum);
  }
  document.getElementById('next').addEventListener('click', onNextPage);

  pdfjsLib.getDocument(url).promise.then(function (pdf) {
    pdfDoc = pdf;
    document.getElementById('page_count').textContent = pdf.numPages;
    renderPage(pageNum);
  }, function (reason) {
    // PDF loading error
    console.error(reason);
  });
}
</script>

<template>
  <div class="card card-small mb-4">
    <div class="col-md-12">
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
              1. Căn cứ
            </button>
          </h2>
          <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              Căn cứ công văn 936
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
              2. Tiêu chuẩn kĩ thuật
            </button>
          </h2>
          <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
            <div class="accordion-body">
              Test
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card card-small mb-4">
    <div class="card-header border-bottom">
      <h6 class="m-0">Remote Signing</h6>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item p-3">
        <strong class="text-muted d-block my-2">Check Certificate <i data-placement="top" data-toggle="tooltip" title="Kiểm tra hiệu lực chứng thư số"><font-awesome-icon :icon="['fas', 'question-circle']" /></i></strong>
        <div class="form-row">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">sp_id</span>
            <input type="text" class="form-control" id="sp_id" placeholder="sp_id theo công văn 936" value="" v-model="form.sp_id">
            <span class="input-group-text" id="basic-addon1">sp_password</span>
            <input type="password" class="form-control" id="sp_password" placeholder="sp_password theo công văn 936" value="" v-model="form.sp_password">
          </div>
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1"><font-awesome-icon :icon="['fas', 'id-card']" /></span>
            <input type="text" class="form-control" id="user_id" placeholder="user_id theo công văn 936" value="" v-model="form.user_id">
            <span class="input-group-text"><font-awesome-icon :icon="['fas', 'certificate']" /></span>
            <select class="form-control" name="select-ca" id="select-ca" v-model="form.selectedOptionCa">
              <option selected value="">Choose CA</option>
              <option value="BkavCA">Bkav CA</option>
              <option value="CA2">CA2</option>
              <option value="MISA-CA">MISA CA</option>
              <option value="INTRUSTCA">Intrust CA</option>
              <option value="Viettel-CA">Viettel CA</option>
              <option value="VNPT-CA">VNPT CA</option>
              <option value="FPT-CA">FPT CA</option>
              <option value="TrustCA">Trust CA</option>
            </select>
          </div>
        </div>

        <button v-if="loadingStates.validateButton == false" class="btn btn-sm btn-primary" @click="validateCertificate">
          Validate
        </button>
        <button class="btn btn-sm btn-primary" v-else>
          <Spinner />
        </button>
        <span v-if="serial_numbers !== null" class="badge badge-pill badge-success ml-2"><i class="material-icons">check</i> Verified</span>
      </li>
      <li class="list-group-item p-3">
        <strong class="text-muted d-block my-2">Upload Pdf File</strong>
        <div class="row">
          <div class="col">
            <Captcha ref="captchaRef" :errorCaptchaHtml="errorCaptchaHtml"/>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input class="form-control" type="file" id="formFile" @change="handleFileChange">
          </div>
        </div>
      </li>
      <li class="list-group-item p-3">
        <strong class="text-muted d-block my-2">Select Position Signature
          <div class="btn-group" role="group" aria-label="Basic example">
            <button class="btn btn-sm btn-white" id="prev">
              <font-awesome-icon :icon="['fas', 'arrow-circle-left']" />
            </button>
            <button class="btn btn-sm btn-white" id="next">
              <font-awesome-icon :icon="['fas', 'arrow-circle-right']" />
            </button>
          </div>
          <span>Trang: <span id="page_num"></span> / <span id="page_count"></span></span>
        </strong>
        <div class="row mb-3" style="text-align: -webkit-center;">
          <div class="col">
            <div class="mb-3" id="containerCanvasPdf" style="overflow: scroll;">
              <canvas id="pdf_canvas" style="display:none;"></canvas>
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item p-3">
        <strong class="text-muted d-block my-2">PDF Signature Property</strong>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="input-group mb-3">
              <span class="input-group-text">Option</span>
              <input v-model="form.timestampUrl" type="text" class="form-control" :disabled="!form.enable_timestamp" placeholder="Default is site's Timestamp url" aria-label="TSA" aria-describedby="basic-addon1">
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text">Option</span>
              <input v-model="form.reason" type="text" class="form-control" placeholder="Default is Reason to signing" aria-label="TSA">
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text">Option</span>
              <input v-model="form.location" type="text" class="form-control" placeholder="Default is Location to signing" aria-label="TSA">
            </div>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">Require</span>
              <select v-if="serial_numbers && serial_numbers.length" v-model="form.serial_number" class="form-control" id="select_certificate_for_signing">
                <option v-for="cert in serial_numbers" :value="cert">{{cert}}</option>
              </select>
              <input v-else type="text" disabled class="form-control" placeholder="Serial numbers are not available." aria-label="TSA">
              <span class="input-group-text"><i data-placement="top" data-toggle="tooltip" title="Trường hợp người dùng có nhiều chứng thư số" style="cursor: pointer;"><font-awesome-icon :icon="['fas', 'question-circle']" /></i></span>
            </div>
          </div>
          <div class="col-md-6">
            <label for="conversationsEmailsToggle" class="col col-form-label"> Appearance Signature <small class="form-text text-muted"> visible or hidden signature on PDF file after signed. Default is visible</small>
              </label>
            <div class="form-row mx-4">
              <div class="form-check form-switch">
                <input v-model="form.visible_signature" class="form-check-input" type="checkbox" role="switch" id="invisible_signature" name="invisible_signature">
                <label class="form-check-label" for="invisible_signature">On/Off</label>
              </div>
            </div>
            <label for="conversationsEmailsToggle" class="col col-form-label"> Timestamp <small class="form-text text-muted"> On or off Embedded timestamp to signature. Default is Off </small>
              </label>
            <div class="form-row mx-4">
              <div class="form-check form-switch">
                <input v-model="form.enable_timestamp" class="form-check-input" type="checkbox" role="switch" id="enable_timestamp" name="enable_timestamp">
                <label class="form-check-label" for="enable_timestamp">On/Off</label>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="list-group-item p-3">
        <strong class="text-muted d-block my-2">Action</strong>
        <div class="row mb-3">
          <div class="col">
            <div class="btn-group" role="group">
              <button v-if="loadingStates.signButton == false" type="button" class="btn btn-sm btn-primary" @click="submitSigning"><font-awesome-icon :icon="['fas', 'signature']" /> Sign PDF</button>
              <button v-else class="btn btn-sm btn-primary"><Spinner /></button>
              <button type="button" class="btn btn-sm btn-warning" @click="handleResetForm"><font-awesome-icon :icon="['fas', 'window-close']" /> Reset</button>
            </div>
            <a v-if="fileSignedUrl" :href="fileSignedUrl">Tải xuống {{ selectedFile.name }}</a>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>

</style>
