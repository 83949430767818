<script setup>
import { ref, onMounted, inject } from 'vue';
import Captcha from './_Captcha.vue';
import axios from 'axios';
const csrfToken = inject('csrfToken')

const captchaRef = ref('');
const verifyData = ref([]);

const handleFileChange = async (event) => {
  const formData = new FormData();
  formData.append('file', event.target.files[0]);
  formData.append('captcha_0', captchaRef.value.captchaKey); // Tên của trường hidden captcha
  formData.append('captcha_1', captchaRef.value.captchaText); // Giá trị captcha người dùng nhập

  try {
    const response = await axios.post('/digital-signature/api_verify_pdf', formData, {
      headers: { 'X-CSRFToken': csrfToken } // Đổi this.$csrfToken thành window.csrfToken (hoặc cách khai báo csrfToken của bạn)
    });
    if (response.data.status == 200) {
      verifyData.value = response.data.data
      console.log(response);
    } else {
      alert(response.data.message);
    }
    captchaRef.value.reloadCaptcha();
  } catch (error) {
    console.error('Error verifying PDF:', error);
  }
};

onMounted(() => {
  captchaRef.value.reloadCaptcha();
});
</script>

<template>
  <div class="container">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="card card-small mt-4">
          <div class="card-header border-bottom">
            <h6 class="m-0">Verify PDF</h6>
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item p-3">
              <small class="text-muted d-block my-2">Upload Pdf File</small>
              <div class="row">
                <div class="col">
                  <div class="text-center">
                    <Captcha ref="captchaRef"/>
                  </div>
                  <input class="form-control" type="file" ref="fileInput" id="formFile" @change="handleFileChange">
                  <small v-if="errorMessage" class="errorMessage mt-2">{{ errorMessage }}</small>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row" v-if="verifyData.length">
      <div class="col-md-8 offset-md-2 text-center mt-4">
        <small>Chi tiết chữ ký</small>
      </div>
      <div class="col-md-8 offset-md-2" v-for="(signer, index) in verifyData" :key="index">
        <hr>
        <ul class="list-group">
          <li class="list-group-item d-flex justify-content-between align-items-center">
            Người ký: {{ signer.name }}
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            Tính hợp lệ của Chứng thư số: {{ signer.validity }}
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            Dấu thời gian: {{ signer.timestamp_status }}
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            Thời gian ký: {{ signer.signed_time }}
          </li>
          <!-- <li class="list-group-item d-flex justify-content-between align-items-center">
            Tính hợp lệ: {{ signer.valid }}
          </li> -->
          <li class="list-group-item d-flex justify-content-between align-items-center">
            Tính toàn vẹn: {{ signer.intact }}
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            OCSP: {{ signer.ocsp }}
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-center">
            CRL: {{ signer.crl }}
          </li>
          <!-- <li class="list-group-item d-flex justify-content-between align-items-center">
            Thời gian xác thực: {{ signer.validation_time }}
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.errorMessage {
  color: rgb(226, 19, 19);
}

h1 {
  font-weight: 500;
  font-size: 2.6rem;
  position: relative;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}
</style>
