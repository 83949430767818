<template>
    <main class="container px-xs-0" id="main-body-content">
        <div class="row px-xs-0">
            <div class="col-md-6 offset-md-3 col-sm-12 col-xs-12">
                <div class="label-detail-post mb-4">
                    <div class="label-detail-post-author">
                        <a href="#" class="">Viết bởi {{ post.author_name ?? 'NONE' }}</a>
                        <span class="fa-regular fa-clock"> <small> - {{ post.created_at ?? 'NONE' }}</small></span>
                    </div>
                    <!-- <div class="label-detail-post-command">
                        <button class="btn btn-primary btn-sm"><i class="fa-regular fa-thumbs-up"></i> 0</button>
                        <button class="btn btn-warning btn-sm"><i class="fa-regular fa-thumbs-down"></i> 0</button>
                        <button class="btn btn-secondary btn-sm"><i class="fa-regular fa-comment"></i> 0</button>
                    </div> -->
                </div>
                <h2>{{ post.title }}</h2>
                <p>{{ post.summary }}</p>
                <div class="post-content" id="post-content" v-html="post.content">
                </div>
                
                <p>Xem thêm:
                    
                </p>
            </div>
            <div class="col-md-3">
                <Toc :content="post.content"/>
            </div>
        </div>
    </main>
</template>
<script setup>
    import axios from 'axios';
    import Toc from '../blog/_Toc.vue';
    import { useRoute } from 'vue-router';
    import { ref, onMounted, computed } from 'vue';

    const post = ref({
        author_name: '',
        created_at: '',
        title: '',
        summary: '',
        content: ''
    });
    const route = useRoute();

    onMounted(() => {
        axios.get('/blog/posts/' + route.params.title_tag)
        .then(response => {
            post.value = response.data;
        })
        .catch(error => {
            console.log(error);
        });
    });

</script>