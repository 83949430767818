<script setup>

</script>

<template>
  <div class="page-header row no-gutters py-4">
    <div class="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
      <span class="text-uppercase page-subtitle">Overview</span>
      <h3 class="page-title">Authentication</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-12 mb-1">
      <p>An API key is a unique string generated by the server, which the client includes in their API requests to authenticate themselves. </p>
      
      <p>API keys are simple to use and are typically included in request headers or query parameters to allow access to specific resources or operations within the API.</p>
      
      <p>All REST API queries require a valid API Key.</p>

      <span>GET /api/resource/ HTTP/1.1 Host: example.com Authorization: Api-Key "Your API-KEY" </span>
    </div>
  </div>
  
</template>