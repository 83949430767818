<script setup>
import { defineComponent } from 'vue';

defineComponent({
  name: 'Footer',
});

defineProps({

})
</script>

<template>
  <ul class="nav">
  <li class="nav-item">
    <a class="nav-link" href="#">Home</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Services</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">About</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Products</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" href="#">Blog</a>
  </li>
</ul>
<span class="copyright ml-auto my-auto mr-2">Developed by Bùi Quốc Anh</span>
</template>