<script setup>
import { watch, toRefs } from 'vue';
import AliasTocList from './_TocList.vue';

const props = defineProps({
  items: Array,
})
</script>

<template>
  <ul>
    <li v-for="item in items">
      <a :href="`#${item.id}`" class="href-table-of-content">{{ item.content }}</a>

      <AliasTocList v-if="item.subheadings.length" :items="item.subheadings" />
    </li>
  </ul>
</template>