<script setup>
import { defineComponent } from 'vue';

defineProps({
  msg: {
    type: String,
    required: true
  }
})
</script>

<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-md-8 offset-md-2">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                1. Cung cấp thông tin
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <strong>1.1. Không thu thập dữ liệu cá nhân</strong>
                <br>
                - Khi sử dụng trang web Gurugrass, chúng tôi không yêu cầu cũng như không lưu trữ bất kỳ một thông tin cá nhân như tên tuổi, địa chỉ, số điện thoại, địa chỉ email ...
                <br>
                <strong>1.2. Dữ liệu được thu thập bởi Công nghệ</strong>
                <br>
                - Dữ liệu thiết bị và trình duyệt: Chúng tôi tự động ghi lại thông tin sau (nếu có) khi bạn truy cập nền tảng Gurugrass: tên và phiên bản hệ điều hành, mã nhận dạng thiết bị và địa chỉ IP. Dữ liệu này được sử dụng để bảo mật hệ thống của chúng tôi và tối ưu hóa cho thiết bị của bạn
                <br>
                - Cookie: Chúng tôi sử dụng cookie và các công nghệ tương tự để nhận dạng bạn và/hoặc (các) thiết bị của bạn, đồng thời cung cấp trải nghiệm cá nhân và liền mạch hơn khi tương tác với Dịch vụ của chúng tôi.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                2. Quyền riêng tư
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                - Các tệp tin khi bạn tải lên trang web của chúng tôi sẽ bị xóa ngay sau khi tiến trình xử lý trước đó đã kết thúc.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-weight: 500;
  font-size: 2.6rem;
  position: relative;
  top: -10px;
}

h3 {
  font-size: 1.2rem;
}

.greetings h1,
.greetings h3 {
  text-align: center;
}

@media (min-width: 1024px) {
  .greetings h1,
  .greetings h3 {
    text-align: left;
  }
}
</style>
