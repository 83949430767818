<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import LayoutPki from './layouts/LayoutPki.vue';
import LayoutDefault from './layouts/LayoutDefault.vue';
import LayoutGuest from './layouts/LayoutGuest.vue';

const route = useRoute();

const layoutComponent = computed(() => {
  switch (route.meta.layout) {
    case 'Blog':
      return LayoutDefault;
    case 'Pki':
      return LayoutPki;
    case 'Guest':
      return LayoutGuest;
    default:
      return LayoutDefault;
  }
});
</script>

<template>
    <component :is="layoutComponent" />
</template>
