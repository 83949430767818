<script setup props="props">
import { ref, toRefs, onMounted } from 'vue';
import axios from 'axios';
import { inject } from 'vue'

const props = defineProps({
  errorCaptchaHtml: String,
})

const { errorCaptchaHtml } = toRefs(props);

const csrfToken = inject('csrfToken')
const captchaKey = ref('');
const captchaImageUrl = ref('');
const captchaText = ref('');

const reloadCaptcha = async () => {
  try {
    const response = await axios.get('/digital-signature/get_captcha');
    captchaKey.value = response.data.captcha_key;
    captchaImageUrl.value = response.data.captcha_url;
    console.log(captchaKey.value, captchaImageUrl.value)

  } catch (error) {
    console.error('Error fetching captcha:', error);
  }
};

onMounted(() => {
  reloadCaptcha();
  // console.log(csrfToken)
});

defineExpose({
  captchaText,
  reloadCaptcha,
  captchaKey,
  captchaImageUrl
});
</script>
<template>
  <div class="input-group mb-1">
    <div class="input-group-text" id="btnGroupAddon"><img :src="captchaImageUrl" alt="Captcha" /></div>
    <button class="btn btn-sm btn-primary" type="button" @click="reloadCaptcha"><font-awesome-icon :icon="['fas', 'rotate']" /></button>
    <input type="text" class="form-control" v-model="captchaText" placeholder="Nhập mã Captcha" />
  </div>
  <ul class="errorlist">
    <li style="color: red;font-size: 0.8125rem;" v-for="error in errorCaptchaHtml">{{error}}</li>
  </ul>
</template>