<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Spinner',
})
</script>
<template>
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</template>