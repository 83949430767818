<script setup>
import { computed, ref, onMounted, watch } from 'vue';
import TocList from './_TocList.vue';
import {slugifyWithCounter} from '@sindresorhus/slugify';

const slugify = slugifyWithCounter();

const headings = ref([]);

const props = defineProps({
  content: String
});

const updateHeadings = () => {
  if (props.content) {
    const postContent = document.createElement('div');
    postContent.innerHTML = props.content;

    const headingElements = postContent.querySelectorAll("h1, h2, h3, h4, h5, h6");
    headings.value = []; // Xóa các tiêu đề trước đó
    headingElements.forEach(el => {

      let id = slugify(el.innerText);
      el.setAttribute("id", id)
      console.log(el)

      headings.value.push({
        id: id,
        level: el.tagName.charAt(1),
        content: el.innerText,
        subheadings: [],
      });
    });
  }
};

// Theo dõi sự thay đổi của content prop
watch(() => props.content, updateHeadings, { immediate: true });

const groupedHeadings = computed(() => {
  let items = [...headings.value];

  for (let i = items.length - 1;i >= 0; i--) {
    let currentItem = items[i];

    let parentItem = items.findLast((item, index) => {
      return item.level < currentItem.level && index < i;
    });

    if (parentItem) {
      parentItem.subheadings.unshift(currentItem);
      items.splice(i, 1);
    }
  }

  return items;
});
</script>

<template>
  <div class="table-of-content">
    <div class="outline-title">
    On this page
    </div>

    <TocList :items="groupedHeadings"/>
  </div>
  
</template>

<style>
.href-table-of-content{
  text-decoration: none;
  color: black;
}
.href-table-of-content:hover {
  cursor: pointer;
  color: orange;
  text-decoration: underline;
}

.table-of-content > ul:first-of-type {
  padding-left: 0px;
}
ul li {
  list-style: none;
}
.outline-title {
    font-weight: 700;
    margin-bottom: 4px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: .4px;
}
</style>