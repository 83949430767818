<script setup>

</script>

<template>
  <div class="page-header row no-gutters py-4">
    <div class="col-12 col-sm-4 text-center text-sm-left mb-4 mb-sm-0">
      <span class="text-uppercase page-subtitle">Overview</span>
      <h3 class="page-title">Rate limits</h3>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-12 mb-1">
      A 429 response will also include a Retry-After header with the number of seconds to wait until retrying your query.
    </div>
  </div>
  
</template>