<template>
    <div class="container mt-5">
        <div class="row">
          <div class="col-md-12 text-center">
            <h2>HỆ THỐNG THỬ NGHIỆM PHÁT TRIỂN DỊCH VỤ</h2>
          </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import Post from './Post.vue';

const currentHost = ref(window.location.origin);
const totalPost = ref(0);
const currentPage = ref(1);
const itemsPerPage = ref(6);
const posts = ref([]);
const nextPageUrl = ref(null);
const prevPageUrl = ref(null);

const totalPages = computed(() => Math.ceil(totalPost.value / itemsPerPage.value));

const changePageWithUrl = async (pageUrl) => {
  if (!pageUrl) {
    alert("Url null");
    return;
  }

  const urlObj = new URL(pageUrl);
  const searchParams = urlObj.searchParams;

  currentPage.value = parseInt(searchParams.get('page'), 10);

  var pageUrlAfterRemoveHost = `${urlObj.pathname}${urlObj.search}`;

  if (isNaN(currentPage.value)) {
    pageUrlAfterRemoveHost += '?page=1';
    currentPage.value = 1;
  }

  try {
    const response = await axios.get(pageUrlAfterRemoveHost);
    router.push({ path: '/blogs', query: { page: currentPage.value } });
    posts.value = response.data.results;
    nextPageUrl.value = response.data.next;
    prevPageUrl.value = response.data.previous;
    totalPost.value = response.data.count;
  } catch (error) {
    console.error('Error fetching posts for page:', error);
  }
};

// Use Vue Router hooks if necessary
const route = useRoute();
const router = useRouter();

onMounted(() => {
  changePageWithUrl(currentHost.value + '/blog/posts/?page=1');
});
</script>
<style scoped>
    .wrap-post {
        height: 100%;
    }

    .wrap-post:hover .box-post {
        cursor: pointer;
        background-color: white;
        box-shadow: rgb(100 100 111 / 20%) 0px 0px 20px 0px!important;
    }

    .box-post {
        box-shadow: rgb(100 100 111 / 20%) 0px 0px 5px 0px;
        transition: 0.5s;
        border-radius: 3px;
    }

    @media only screen and (max-width: 768px) {
    .show {
      display: block!important;
      animation: 0.1s linear 0s slide backwards;
    }
  
    .navbar-menu {
      flex-wrap: wrap;
      padding: 5px 0px 5px 0px;
    }
    .header-default {
      position: relative;
    }
    .navbar-list {
      /* width: 100%; */
      display: none;
      /* position: absolute;
      left: 0px;
      top: 42px; */
      flex-grow: 1;
      flex-basis: 100%;
    }
  
    main {
      margin-top: 30px;
    }
  
    .header-wrap {
      width: 100%;
  
      position: relative;
      top: 0px;
      z-index: 4!important;
  
      margin-bottom: 0px;
  
      -webkit-transition: all 0.3s ease;
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }
  
    .fixed-menu {
      position: fixed!important;
      top: 0px;
  
      padding-top: 0px;
      padding-bottom: 0px;
    }
  
    .main-add-margin-top {
      margin-top: 140px;
    }
    /* For mobile phones: */
    header .header-default {
      position: relative;
      padding: 0px 0px 0px 0px;
    }
    .row .px-lg-5 .px-xs-0 {
      padding: 0px 0px 0px 0px;
    }
  
    .navbar-items {
      /* width: 100%; */
      /* display: flex; */
      flex-direction: column;
      padding-left: 0px;
      align-items: flex-start;
      /* display: block; */
      z-index: -999;
    }
  
    @keyframes slide {
      from { top: -100px; }
      to {
        top: 1px;
      }
    }
    .navbar-logo a small {
      font-size: 18px;
    }
    .navbar-logo small {
      font-size: 7px;
    }
  
    .navbar-brand {
      margin-right: 0px;
    }
  
    .navbar-logo {
      text-align: center!important;
    }
    
    .nav-item {
      background-color: white;
      padding: 6px 10px 6px 0px;
      font-size: 12px;
    }
  
    .icon-bar-mobile {
      /* margin-left: auto; */
      background-color: white;
      padding: 5px 12px 5px 12px;
      margin-top: 3px;
      margin-bottom: 3px;
      border: 1px solid #0000005e;
      border-radius: 3px;
      display: block;
    }
  
    .icon-bar-mobile span{
      font-size: 22px;
      color: var(--text_color);
    }
  
    #main-body-content {
      margin-top: 15px;
    }
  
    /* 
    page view
     */
    
    .label-detail-post-command {
      flex-grow: 1;
      flex-basis: 100%;
    }
  
    .label-detail-post-author {
      font-size: 10px;
    }
  
    .label-detail-post-author a {
      color: var(--text_color);
      text-decoration: none;
      font-weight: bold;
    }
    .post-content img {
      width: 100%!important;
      height: auto!important;
    }
  }
</style>