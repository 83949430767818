<template>
    <div class="card box-post" :key="post.title_tag">
        <router-link :to="{name: 'DetailPost', params: {title_tag: post.title_tag}}">
            <img class="card-img-top" v-lazy="post.imageFiler" :alt="post.title_tag">
        </router-link>
        <div class="card-body">
            <div class="card-info">
                <small>Ngày tạo: {{ post.created_at }}</small>
                <small class="card-author"><i class="fa-solid fa-user"></i>{{ post.user_name }}</small>
                <div class="clearfix"></div>
            </div>
            <p class="card-title">
                <router-link :to="{name: 'DetailPost', params: {title_tag: post.title_tag}}" class="card-tag">
                    {{ post.title }}
                </router-link></p>
                <div>
                    <h6 v-for="(category, index) in post.categories" :key="index" :class="['me-2', 'd-inline-block']">
                        <span class="badge text-bg-light">{{ category }}</span>
                    </h6>
                </div>
            <p class="card-summary">{{ post.summary }}</p>
            <p class="card-readmore">
                <router-link :to="{name: 'DetailPost', params: {title_tag: post.title_tag}}">Đọc tiếp <i class="fa-solid fa-angles-right"></i></router-link>
            </p>
        </div>
    </div>
</template>
<script>
    
    export default {
        props: ['post'],
    }
</script>
<style scoped>
    .box-post a{
        width: 100%;
        display: block;
        height: 100%;
    }
    .page-link {
        color: var(--text_color);
    }
    .card-img-top {
        object-fit: fill;
        width: 100%;
        height: 350px;
    }
    .card {
        margin-bottom: 15px!important;
    }

    .card-body {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5px;
        height: 100%;
    }
    
    .card-author i {
        font-size: 10px;
        color: #155580;
    }
    .card-body .card-info .card-author {
        font-size: 10px;
        font-weight: 600;
        color: #155580;
    
        margin-right: 5px;
        float: right;
    }

    .card-body .card-info .card-tag {
        font-size: 11px;
        font-weight: 400;
        color: #8d9092;
    
        float: left;
    }
    
    .card-body .card-title {
        font-weight: 700;
        color: var(--text_color);
    
        margin-top: 10px;
    }
    
    .card-body .card-title a {
        color: var(--text_color)!important;
        text-decoration: none;
    }
    
    .card-body .card-summary {
        font-size: 14px;
        font-weight: 300;
        color: #8d9092;
    }
    
    .card-body .card-readmore a{
        font-size: 11px;
        font-weight: 300;
        color: #8d9092;
    }
</style>