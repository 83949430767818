<template>
  <router-view />
</template>
<script setup>
// import { onMounted } from 'vue';
// import { useRouter } from 'vue-router';

// const router = useRouter();

// onMounted(() => {
//   router.push({ path: '/blogs', query: { page: 1 } });
// });
</script>